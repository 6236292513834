import React from 'react'

import Layout from '../components/layout'
import Logo, { LinearGradient } from '../components/logo'
import SEO from '../components/seo'

import './index.css'

const gradient: LinearGradient = [
  { color: '#FF5A87', opacity: 1, offset: 0 },
  { color: '#FF5A5F', opacity: 0.5, offset: 50 },
  { color: '#FF7840', opacity: 0, offset: 100 },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Lona" />
    <Logo width={270} fill={gradient} />
    <span className="download">
      <a
        className="link"
        href="https://lona.design/releases/LonaStudio_1.0.11.dmg"
      >
        Download
      </a>{' '}
      for Mac
    </span>
  </Layout>
)

export default IndexPage
